import(/* webpackMode: "eager" */ "/mnt/website/ztaos/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/mnt/website/ztaos/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/mnt/website/ztaos/public/icon/arrow-right.svg");
import(/* webpackMode: "eager" */ "/mnt/website/ztaos/public/icon/const/home/icon1.svg");
import(/* webpackMode: "eager" */ "/mnt/website/ztaos/public/icon/const/home/icon2.svg");
import(/* webpackMode: "eager" */ "/mnt/website/ztaos/public/icon/const/home/icon3.svg");
import(/* webpackMode: "eager" */ "/mnt/website/ztaos/public/icon/const/home/icon4.svg");
import(/* webpackMode: "eager" */ "/mnt/website/ztaos/public/icon/const/home/icon5.svg");
import(/* webpackMode: "eager" */ "/mnt/website/ztaos/public/icon/const/home/icon6.svg");
import(/* webpackMode: "eager" */ "/mnt/website/ztaos/public/icon/const/home/quality-1.svg");
import(/* webpackMode: "eager" */ "/mnt/website/ztaos/public/icon/const/home/quality-2.svg");
import(/* webpackMode: "eager" */ "/mnt/website/ztaos/public/icon/const/home/quality-3.svg");
import(/* webpackMode: "eager" */ "/mnt/website/ztaos/public/icon/const/home/quality-4.svg");
import(/* webpackMode: "eager" */ "/mnt/website/ztaos/public/icon/const/home/quality-5.svg");
import(/* webpackMode: "eager" */ "/mnt/website/ztaos/public/icon/const/home/s1.svg");
import(/* webpackMode: "eager" */ "/mnt/website/ztaos/public/icon/const/home/s2.svg");
import(/* webpackMode: "eager" */ "/mnt/website/ztaos/public/icon/const/home/s3.svg");
import(/* webpackMode: "eager" */ "/mnt/website/ztaos/public/icon/const/home/s4.svg");
import(/* webpackMode: "eager" */ "/mnt/website/ztaos/public/images/home/banner.jpg");
import(/* webpackMode: "eager" */ "/mnt/website/ztaos/public/images/home/customer-1.png");
import(/* webpackMode: "eager" */ "/mnt/website/ztaos/public/images/home/customer-2.png");
import(/* webpackMode: "eager" */ "/mnt/website/ztaos/public/images/home/customer-3.png");
import(/* webpackMode: "eager" */ "/mnt/website/ztaos/public/images/home/customer-4.png");
import(/* webpackMode: "eager" */ "/mnt/website/ztaos/public/images/home/customer-5.png");
import(/* webpackMode: "eager" */ "/mnt/website/ztaos/public/images/home/customer-6.png");
import(/* webpackMode: "eager" */ "/mnt/website/ztaos/public/images/home/feature-bg.png");
import(/* webpackMode: "eager" */ "/mnt/website/ztaos/public/images/home/quality-bg.png");
import(/* webpackMode: "eager" */ "/mnt/website/ztaos/public/images/home/service-1.png");
import(/* webpackMode: "eager" */ "/mnt/website/ztaos/public/images/home/service-2.png");
import(/* webpackMode: "eager" */ "/mnt/website/ztaos/public/images/home/service-3.png");
import(/* webpackMode: "eager" */ "/mnt/website/ztaos/public/images/home/tongdian.png");
import(/* webpackMode: "eager" */ "/mnt/website/ztaos/public/images/home/total-bg.png")